@media print {
  @page {
    size: 297mm 520mm;
    margin: 0;
  }
  html {
    image-rendering: auto;
    -webkit-print-color-adjust: exact; /* For better color accuracy */
    resolution: 300dpi; /* Adjust this to your preferred DPI */
  }
}
