/* HeroNew */
@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-UltraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-UltraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "HeroNew";
  src: url('../fonts/HeroNew-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

/* TitilliumWeb */
@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url('../fonts/TitilliumWeb-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}